<template>
<div class="affairs_page">
  <div class="affairs-main">
    <!--agree-head start-->
    <div class="agree-head">
      <div class="key_page_select">
        <div class="select_item">
          <div class="label">时间::</div>
          <div class="select">
            <el-date-picker
                v-model="value1"
                type="date"
                placeholder="选择日期">
            </el-date-picker>
          </div>
        </div>
        <div class="select_item">
          <div class="label">区域:</div>
          <div class="select">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="select_item">
          <div class="label">门店:</div>
          <div class="select">
            <el-select v-model="value" placeholder="请选择" size="160px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="select_item">
          <div class="label">职位:</div>
          <div class="select">
            <el-select v-model="value" placeholder="请选择" size="160px">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="select_item">
          <div class="label">姓名:</div>
          <div class="select">
            <el-input v-model="input" placeholder="请输入"></el-input>
          </div>
        </div>
        <router-link to="/personage/AffairsStaffAdd" class="affairs-add-btn">新增员工</router-link>
      </div>
    </div>
    <!--agree-head end-->
    <!--affairs-content start-->
    <div class="affairs-content">
      <table class="affairs-table">
        <tr>
          <th>工号</th>
          <th>姓名</th>
          <th>性别</th>
          <th>手机号</th>
          <th>身份证号</th>
          <th>入职时间</th>
          <th>职位</th>
          <th>操作</th>
        </tr>
        <tr>
          <td>125845</td>
          <td>刘思雨</td>
          <td>女</td>
          <td>15864258652</td>
          <td>410185425869541258</td>
          <td>2019-10-25</td>
          <td>经纪人</td>
          <td>
            <div class="affairs-table-btn">
              <router-link class="btn color-orange" to="/personage/AffairsStaffDetail">查看</router-link>
              <el-button class="btn color_blue">修改</el-button>
              <el-button class="btn color-red">离职</el-button>
              <el-button class="btn" @click="$refs.staff.open()">调店</el-button>
            </div>
          </td>
        </tr>
        <tr>
          <td>125845</td>
          <td>刘思雨</td>
          <td>女</td>
          <td>15864258652</td>
          <td>410185425869541258</td>
          <td>2019-10-25</td>
          <td>经纪人</td>
          <td>
            <div class="affairs-table-btn">
              <el-button class="btn color-orange">查看</el-button>
              <el-button class="btn color-9">已离职</el-button>
            </div>
          </td>
        </tr>
        <tr>
          <td>125845</td>
          <td>刘思雨</td>
          <td>女</td>
          <td>15864258652</td>
          <td>410185425869541258</td>
          <td>2019-10-25</td>
          <td>经纪人</td>
          <td>
            <div class="affairs-table-btn">
              <el-button class="btn color-orange">查看</el-button>
              <el-button class="btn color_blue">修改</el-button>
              <el-button class="btn color-red">离职</el-button>
            </div>
          </td>
        </tr>
      </table>
      <div class="paging">
        <el-pagination
            background
            layout="prev, pager, next, jumper, ->, total"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--affairs-content end-->
    <!-- 调店弹框 S -->
    <staff-pop ref="staff"></staff-pop>
    <!--  调店弹框弹框 E -->
  </div>
</div>
</template>

<script>
import StaffPop from '../../components/StaffPop'
export default {
  components: { StaffPop},
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
    }
  }

};
</script>

<style scoped lang="less">
.key_page_select{
  display:flex;align-items:center;flex-wrap:wrap;margin:0 -10px;
  .select_item{display:flex;font-size:14px;color:#666;align-items:center;padding:10px 0px;margin:0 10px;}
  .label{margin-right:10px;white-space:nowrap;width:80px;text-align:right;}
  .select{width:160px;}
  /deep/ .el-input__inner{border:1px solid #ccc;border-radius:0;}
}
.agree_a{color:#3273F6;cursor:pointer;}
/deep/.key_page_select .el-date-editor.el-input, .el-date-editor.el-input__inner{width:160px;}
.agree_main{background:#fff;padding-bottom:30px;}
.agree-head{margin:0 20px;padding:20px 0px;position:relative;}
.affairs-main{background:#fff;padding-bottom:30px;}
.affairs-head{}
.affairs-add-btn{width:100px;height:40px;padding:0px;border-radius:4px;background:#3273F6;text-align:center;color:#fff;line-height:40px;font-size:16px;position:absolute;top:30px;right:0px;}
.affairs-content{padding:0 20px;}
.affairs-table{width:100%;border:1px solid #EBEBEB;border-collapse: collapse}
.affairs-table th{background:#F5F5F5;border:1px solid #EBEBEB;border-top:none;border-bottom:none;padding:10px;text-align:center;font-weight:normal;font-size:14px;}
.affairs-table td{padding:10px;border-left:1px solid #EBEBEB;border-right:1px solid #EBEBEB;text-align:center;font-size:14px;}
.affairs-table-btn{
  display:flex;
  .btn{padding:0px;background:none;outline:none;margin:5px;border:none;}
  .color-orange{color:#FF9600}
  .color-red{color:#FF5151;}
}
.affairs-table tr td{background:#F7FCFF}
.affairs-table tr:nth-child(2n) td{background:#fff}
</style>